import { LogLevel } from '@wellro/utils';

export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyANykIkIsUPn4L_bo3zPfNS8f9o4Dq8Kj4',
    authDomain: 'wellro-wellness-preprod.firebaseapp.com',
    projectId: 'wellro-wellness-preprod',
    storageBucket: 'wellro-wellness-preprod.appspot.com',
    messagingSenderId: '313313290513',
    appId: '1:313313290513:web:7eceb2fd02947defd384b0',
    measurementId: 'G-YD0L4KBCXJ',
  },
  sentry: {
    dsn:
      'https://a5b0288e22064f479e678171f3b079cf@o4504682974740480.ingest.sentry.io/4504693552250880',
    env: 'pre-production',
  },
  tenantId: 'system-user-mrqbb',
  logLevel: LogLevel.WARNS,
  hostUrl: 'https://preprod-admin.wellro.life',
};
